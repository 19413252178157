<template>
  <div>
    <PatientFilter
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Patients" iconTable="fas fa-user-md" />
      <div class="m-2">
        <PatientAdd
          :is-add-new-patient-sidebar-active.sync="isAddNewPatientSidebarActive"
          @createPatient="createPatient" />
        <verifyCupon :verifyBool.sync="verifyBool" @createPatient="createPatient" />
        <verifyMembership
          :userId="userId"
          :verifyBool.sync="checkMembershipBool"
          @createPatient="createPatient" />
        <AppointmentAdd
          v-if="isAddNewAppointmentSidebarActive"
          :is-add-new-appointment-sidebar-active.sync="isAddNewAppointmentSidebarActive"
          :userAppointment.sync="userAppointment"
          @createAppointment="createAppointment" />
        <membershipAdd
          :isAddNewmembershipSidebarActive.sync="isAddNewmembershipSidebarActive"
          :patient="userAppointment"
          @createmembership="createMembership" />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25">
            </treeselect>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="info"
                class="mr-2"
                @click="verifyBool = true"
                :disabled="loading"
                v-if="$can('index', 'appointment')">
                <span v-if="!loading" class="text-nowrap">Check cupon</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
              <b-button
                variant="primary"
                @click="isAddNewPatientSidebarActive = true"
                :disabled="loading"
                v-if="$can('index', 'appointment')">
                <span v-if="!loading" class="text-nowrap">Add Patient</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="tableColumns"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found">
        <template #cell(view)="data">
          <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails" />
        </template>

        <template #cell(show)="row" colspan="1">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails">
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1">
                <strong>Full Name : </strong>{{ row.item.name }}
                {{ row.item.lastname }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Identification : </strong>{{ row.item.identification }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Email : </strong>{{ row.item.email }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t('Form.City') }} : </strong>{{ row.item.cities_id }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t('Form.Gender') }} : </strong>{{ row.item.gender }}
              </b-col>
            </b-row>

            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(patient)="data">
          <b-media vertical-align="center">
            <b-link :to="{ name: 'insurance-patients-view', params: { id: data.item.id } }">
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }} {{ data.item.lastname }}
              </span>
              <small class="text-muted">{{ data.item.email }}</small>
            </b-link>
          </b-media>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="resolveUserStatusVariant(data.item.status)"
              class="badge-glow cursor-pointer">
              <span class="align-text-top text-capitalize">{{
                data.item.status ? $t('Active') : $t('Inactive')
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(phone)="data">
          <div class="text-nowrap">
            <span v-if="data.item.phone" class="align-text-top text-capitalize">{{
              formatPhone(data.item.phone)
            }}</span>
          </div>
        </template>

        <template #cell(visit_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.histories && data.item.histories.visit_number
            }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              variant="flat-info"
              class="btn-icon"
              v-b-tooltip.hover.top="'Add Appointment'"
              v-if="$can('store', 'appointment')"
              @click="
                addAppointment(data.item), (isAddNewAppointmentSidebarActive = true)
              ">
              <i class="far fa-calendar-plus"></i>
            </b-button>
            <b-button
              variant="flat-info"
              class="btn-icon"
              v-b-tooltip.hover.top="'Add Membership'"
              @click="addMembership(data.item), (isAddNewmembershipSidebarActive = true)">
              <i class="fas fa-award"></i>
            </b-button>
            <b-dropdown variant="link" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                v-if="$can('show', 'patients')"
                :to="{ name: 'insurance-patients-view', params: { id: data.item.id } }">
                <i class="far fa-eye"></i>
                <span class="align-middle ml-2">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('show', 'patients')"
                @click="openMembershipCheck(data.item.id)">
                <i class="far fa-bookmark"></i>
                <span class="align-middle ml-2">Check Membership</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('update', 'patients')"
                :to="{ name: 'insurance-patients-view', params: { id: data.item.id } }">
                <i class="fas fa-pen"></i>
                <span class="align-middle ml-2">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('index', 'administration')"
                @click="deletePatient(data.item)">
                <i class="fas fa-trash-alt"></i>
                <span class="align-middle ml-2">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #cell(is_membership)="row">
          <span>
            <i
              :class="
                row.item.is_membership
                  ? 'fa fa-star text-warning'
                  : 'fa fa-star text-muted'
              "></i>
          </span>
        </template>
      </b-table>

      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BMedia,
  // BAvatar,
  BLink,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import axiosCV from '@/core/services/apiInsurance/patient'

import ToastNotification from '@/components/ToastNotification'
import SpinnerLoading from '@/components/SpinnerLoading'
import PaginationTable from '@/components/PaginationTable'
import TitleTable from '@/components/TitleTable.vue'
import GoBack from '@/components/ButtonBack.vue'
import Swal from 'sweetalert2'

import AppointmentAdd from '@/view/pages/appointment/actions/AppointmentAdd.vue'
import MembershipAdd from '@/view/pages/admin/appointment/membership/membershipAdd.vue'
import PatientFilter from './PatientFilter.vue'
import PatientAdd from './PatientAdd.vue'
import verifyCupon from './verifyCupon.vue'
import verifyMembership from './verifyMembership.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormCheckbox,
    BMedia,
    BLink,
    BButton,

    PatientFilter,
    AppointmentAdd,
    PatientAdd,
    verifyMembership,

    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    verifyCupon,
    MembershipAdd,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tableColumns: [
        'show',
        { key: 'id', sortable: true },
        { key: 'patient', sortable: true },
        { key: 'phone', sortable: true },
        { key: 'date_birth', label: 'DOB', sortable: true },
        { key: 'visit_number', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions' },
        { key: 'is_membership' },
      ],
      userId: 0,
      items: null,
      loading: false,
      checkMembershipBool: false,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map(i => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      userFilter: null,
      isAddNewPatientSidebarActive: false,
      verifyBool: false,
      isAddNewAppointmentSidebarActive: false,
      isAddNewmembershipSidebarActive: false,
      userAppointment: null,
      userRol: JSON.parse(localStorage.getItem('userData')).roles[0],
    }
  },
  mounted() {
    this.getUser()
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getUser()
      } else {
        this.searchFilter(this.userFilter)
      }
    },
  },
  methods: {
    getUser() {
      axiosCV
        .patientList(this.perPage)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data
          this.totalUsers = total
          this.dataMeta.of = total
          this.dataMeta.from = res.from
          this.dataMeta.to = res.to
          this.currentPage.page = current_page
        })
    },
    deletePatient(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosCV.patientDelete(item.id).then(response => {
            this.$refs.toast.success('Patient type has been deleted successfully')
            this.pageChanged(this.currentPage.page)
          })
        }
      })
    },
    formatPhone(phone) {
      return phone
        .toString()
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    },
    resolveUserStatusVariant(status) {
      if (status) return 'success'
      return 'danger'
    },
    addAppointment(item) {
      this.userAppointment = item
    },
    addMembership(item) {
      this.userAppointment = item
    },
    openMembershipCheck(id) {
      this.userId = id
      this.checkMembershipBool = true
    },
    createPatient({ type, message }) {
      this.NotificationCheck({ type, message })

      if (type === 'ERROR') {
        Swal.fire({
          title: 'Error!',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.verifyBool = false
      this.isAddNewPatientSidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    createAppointment({ type, message }) {
      this.NotificationCheck({ type, message })

      if (type !== 'danger') {
        this.isAddNewAppointmentSidebarActive = false
      }
      this.pageChanged(this.currentPage.page)
    },
    createMembership({ type, message }) {
      this.NotificationCheck({ type, message })

      if (type !== 'danger') {
        this.isAddNewmembershipSidebarActive = false
      }
      this.pageChanged(this.currentPage.page)
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV
          .patientPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      } else {
        axiosCV
          .patientFilterPagination(this.perPage, page, this.userFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      }
    },
    searchFilter(value) {
      this.loading = true
      this.userFilter = value
      axiosCV
        .patientFilter(this.perPage, value)
        .then(({ data, total, current_page, ...res }) => {
          this.loading = false
          this.items = data
          this.totalUsers = total
          this.dataMeta.of = total
          this.dataMeta.from = res.from
          this.dataMeta.to = res.to
          this.currentPage.page = current_page
        })
        .catch(() => {
          this.loading = false
        })
    },
    filterPage(value) {
      this.filtro = value
    },
    NotificationCheck({ type, message }) {
      if (!type || !message) return
      this.$refs.toast[type](message)
    },
  },
}
</script>

<style></style>
